body {
    padding: 0;
    margin: 0;
    background: #001529fa;
    font-family: Raleway, calibri;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader {
    width: 140px;
    height: 140px;
}

@media (max-width: 550px) {
    .loader {
        width: 115px;
        height: 115px;
    }
}

@media (max-width: 400px) {
    .loader {
        width: 90px;
        height: 90px;
    }
}